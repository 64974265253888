<template>
  <div class="list">
    <Breadcrumb name1="合成管理" name2="合成关系列表" />
    <el-card>
      <!-- 按钮搜索/添加区域 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" @click="jumpToAddSynthetic">添加合成关系</el-button>
        </el-col>
      </el-row>
      <!-- table区域 -->
      <el-row>
        <el-table :data="goodsList" border style="width: 100%">
          <el-table-column prop="id" label="ID" width="70"></el-table-column>
          <el-table-column prop="target_id" label="合成目标ID"></el-table-column>
          <el-table-column prop="TargetName" label="合成目标"></el-table-column>
          <!-- <el-table-column prop="number" label="需要数量"></el-table-column> -->
          <el-table-column label="需要艺术品">
            <template slot-scope="scope">
              <span v-html="scope.row.ArtNames">
              </span>
            </template>
          </el-table-column>
          <el-table-column label="需要盲盒艺术品">
            <template slot-scope="scope">
              <span v-html="scope.row.BoxNames">
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="factor" label="概率"></el-table-column>
          <el-table-column prop="is_published" label="是否发布" :formatter="formatBoolean"></el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-edit"
                @click="editById(scope.row.id)"
              ></el-button>
              <el-button v-if="!scope.row.is_published"
                type="primary"
                size="mini"
                @click="publishById(scope.row.id, scope.row.is_published)"
                >发布</el-button>
              <el-button v-else-if="scope.row.is_published"
                type="primary"
                size="mini"
                @click="publishById(scope.row.id, scope.row.is_published)"
                >取消发布</el-button
              >
              <el-button
                type="danger"
                size="mini"
                icon="el-icon-delete"
                @click="removeById(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryInfo.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import Breadcrumb from 'components/content/breadcrumb/Breadcrumb'
import { number } from 'echarts'
export default {
  name: 'List',
  components: {
    Breadcrumb,
  },
  data() {
    return {
      generateLoading:false,
      // 查询参数对象
      queryInfo: {
        page: 1,
        page_size: 10,
      },
      // 艺术品列表
      goodsList: [],
      // 总数据条数
      total: 0,
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    // 根据分页获取列表数据
    getDataList() {
      const _that = this;
      _that.$http({
        method: 'get',
        url: '/api/v1/admin/art-synthetic/list',
        params: _that.queryInfo,
        timeout: 10000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          // 'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        // loading: loading
      })
        .then((res) => {
          _that.$message.success('获取合成列表成功!')
          _that.goodsList = res.data.list
          _that.total = res.data.pager.total_rows
        })
        .catch((res) => {
          return _that.$message.error('获取合成列表失败!')
        })
    },
    //发布数据
    publishById(id, is_published) {
      var msg = "发布"
      if (is_published){
        msg = "取消发布"
      }
      let url_data = '/api/v1/admin/art-synthetic/publish/' + id
      this.$http({
        method: 'put',
        url: url_data,
        timeout: 10000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          'Content-Type': 'application/json; charset=UTF-8',
        },
      }).then((res) => {
        if (res.data.status == 200){
          this.$message.success('合成关系'+msg+'成功!')
        }else{
          this.$message.error(res.data.msg )
        }
        this.getDataList()
      }).catch((res) => {
        this.$message.error('合成关系'+msg+'失败!')
      })
    },
    // 显示的页数改变
    handleSizeChange(newSize) {
      this.queryInfo.page_size = newSize
      this.getDataList()
    },
    // 页码的改变
    handleCurrentChange(newValue) {
      this.queryInfo.page = newValue
      this.getDataList()
    },
    async removeById(id){
      const confirmResult = await this.$confirm(
        '此操作将刪除该艺术品, 是否继续?',
        '删除',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)
      console.log(confirmResult)
      if (confirmResult !== 'confirm') {
        this.$message.info('删除操作已取消!')
        return
      }
      this.$http({
        method: 'delete',
        url: '/api/v1/admin/art-synthetic/' + id,
        timeout: 10000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
      .then((res) => {
        if (res.data.status == 200){
          this.$message.success('删除成功!')
        }
        this.getDataList()
      })
      .catch((res) => {
        return this.$message.error('删除失败!')
      })
    },
    formatBoolean: function (row, index) {
      if (row.is_published) {
        return '是' //根据自己的需求设定
      } else {
        return '否'
      }
    },
    editById(id) {
      let route = 'synthetic-add/' + id
      this.$router.push(route)
    },
    jumpToAddSynthetic: function () {
      this.$router.push('/synthetic-add')
    },

  },
}
</script>

<style lang="less" scoped>
.el-table {
  margin: 15px 0 0;
}
</style>
<style>
  .el-dialog{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 0!important;
    transform: translate(-50%, -50%);
  }
</style>